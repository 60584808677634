import React, { useState, useMemo, useContext, useEffect, useRef } from 'react';
import SecureLS from 'secure-ls';
import { UserOwner, User } from 'types/User';
import { useUserOwner } from 'views/auth/hook/auth_hook';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import BaseService from 'services/api/BaseService';
import { App } from 'antd';
import * as Userpilot from 'tools/Userpilot';

const ls = new SecureLS({ encodingType: 'aes' });

type AuthPayload = {
  token: string;
  user: User;
};

export interface AuthContextType {
  auth?: AuthPayload;
  owner?: UserOwner;
  ownerIsLoading?: boolean;
  login: (auth: AuthPayload) => void;
  logout: () => void;
}

export const AuthContext = React.createContext<AuthContextType>({
  auth: undefined,
  owner: undefined,
  ownerIsLoading: false,
  login: () => {},
  logout: () => {},
});

interface AuthContextProviderProps {
  children: React.ReactNode;
}

function getUser(): User {
  try {
    return ls.get('user');
  } catch (error) {
    return {} as User;
  }
}

export function getToken(): string {
  try {
    return ls.get('token');
  } catch (error) {
    return '';
  }
}

export function getTokenExpiration(): number {
  const token = getToken();
  if (!token) {
    return 0;
  }
  const jwtPayload = JSON.parse(atob(token.split('.')[1]));
  return jwtPayload.exp * 1000;
}

export function AuthContextProvider({ children }: AuthContextProviderProps) {
  const { data: owner, isLoading: ownerIsLoading } = useUserOwner();
  const queryClient = useQueryClient();
  const refreshTokenTimeout = useRef<ReturnType<typeof setTimeout>>();
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const user = useMemo(getUser, []);
  const token = useMemo(getToken, []);
  const initialAuth = user && token ? { user, token } : undefined;

  const [auth, setAuth] = useState<AuthContextType['auth']>(initialAuth);

  // Connexion
  const login: AuthContextType['login'] = (auth) => {
    setAuth(auth);
    ls.set('token', auth?.token);
    ls.set('user', auth?.user);
    startTokenKeepAlive();
  };

  // Déconnexion
  const logout = () => {
    stopTokenKeepAlive();
    setAuth(undefined);
    queryClient.clear();
    ls.remove('token');
    ls.remove('user');
  };

  const startTokenKeepAlive = async () => {
    // Renouvelle le token 20 secondes avant son expiration
    const renewAfter = getTokenExpiration() - new Date().getTime() - 20000;
    clearTimeout(refreshTokenTimeout.current);
    refreshTokenTimeout.current = setTimeout(() => {
      checkAndRenewToken();
    }, renewAfter);
  };

  const stopTokenKeepAlive = () => {
    clearTimeout(refreshTokenTimeout.current);
  };

  const checkAndRenewToken = async () => {
    const hasExistingToken = !!getToken();
    const ttl = getTokenExpiration() - new Date().getTime();

    // Session expirée (on considère qu'on n'aura pas le temps de renouveler le token en moins de 10s)
    if (ttl <= 10000) {
      logout();
      // Envoie une notification de session expirée uniquement s'il existait précédemment un token
      if (hasExistingToken) {
        notification.info({
          message: t('global:session_expired'),
        });
      }
    } else {
      // Renouvelle le token
      stopTokenKeepAlive();
      await BaseService.renewToken();
      // Relance le keepalive avec un délai au cas où le renouvellement aurait échoué afin d'éviter de spammer le back-end
      setTimeout(() => {
        if (getToken()) {
          startTokenKeepAlive();
        }
      }, 2000);
    }
  };

  useEffect(() => {
    checkAndRenewToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      Userpilot.identify(user);
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
        owner,
        ownerIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);
